<template>
    <v-container fill-height>
        <v-layout justify-center wrap>
            <v-flex md12>
                <material-card title="Products"
                               text="">
                    <template :slot="'header_actions'">
                        <v-btn rounded fab small @click="showUploadModal()">
                            <v-icon color="primary">mdi-file-upload</v-icon>
                        </v-btn>
                    </template>

                    <v-row class="mx-8">

                        <v-text-field
                                class="mx-2"
                                v-model="filter.name"
                                label="Name"
                        ></v-text-field>

                        <v-text-field
                                max-width="400px"
                                class="mx-2"
                                v-model="filter.item_number"
                                label="Item Number"
                        ></v-text-field>

                        <v-btn color="primary" fab small
                               @click="filter_results(filter)"
                        >
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>

                    </v-row>

                    <v-data-table :headers="headers"
                                  :items="products"
                                  header-props.sort-icon="mdi-menu-up"
                                  item-key="id"
                                  :loading="loading"
                                  :loading-text="'Loading please wait'"
                                  hide-default-footer
                                  light>

                        <template v-slot:item.actions="{ item }">
                            <v-btn small rounded text fab
                                   @click="edit_product(item.id)"
                                   v-tooltip="'Edit Product'">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn color="primary" text fab small
                                   @click="load_factory_details(item)"
                            >
                                <v-icon>mdi-information-outline</v-icon>
                            </v-btn>
                        </template>

                        <template v-slot:item.album="{ item }">
                            <template v-if="!!item['album']">
                                <v-layout v-if="!!item['album']['files']">
                                    <v-img v-for="(file, key) of item['album']['files']"
                                           :key="key"
                                           :src="backend_url + file['url']"
                                           :alt="file['name']"
                                           height="40"
                                           width="40">
                                    </v-img>
                                </v-layout>
                            </template>

                        </template>

                    </v-data-table>

                    <div class="text-center">
                        <v-pagination v-model="page"
                                      circle
                                      :total-visible="7"
                                      :length="max_count"></v-pagination>
                    </div>
                </material-card>
            </v-flex>
        </v-layout>
        <v-dialog width="800" v-model="factory_dialog">
            <v-card>
                <v-card-title>
                    {{ factory.factory.name }}
                </v-card-title>
                <v-card-text>
                     <v-container>
                         <v-row :justify="'center'" :align="'center'">
                             <v-col cols="12" sm="6" md="6">
                                 <strong class="headline font-weight-bold">{{ factory.factory.address }}</strong>
                                 <br>
                                 <h2 class="display-1">
                                     Contacts
                                 </h2>
                                 <div v-for="user in factory.factory.users" class="pt-4">
                                     {{ user.email }}
                                 </div>
                             </v-col>
                             <v-col cols="12" sm="12" md="6" lg="6">
                                 <v-simple-table>
                                     <tr v-if="!!factory['album']">
                                         <th colspan="2" class="text-center">
                                             <template >
                                             <v-img v-for="(file, key) of factory['album']['files']"
                                                    :key="key"
                                                    :src="backend_url + file['url']"
                                                    :alt="file['name']"
                                                    height="100"
                                                    width="100">
                                             </v-img>
                                             </template>
                                         </th>
                                     </tr>
                                     <tr>
                                         <th>Name</th>
                                         <th>{{ factory.name }}</th>
                                     </tr>
                                     <tr>
                                         <th>Desc</th>
                                         <th>{{ factory.description }}</th>
                                     </tr>
                                     <tr>
                                         <th>Item Number</th>
                                         <th>{{ factory.item_number }}</th>
                                     </tr>
                                     <tr>
                                         <th>FOB</th>
                                         <th>{{ factory.fob_price }}</th>
                                     </tr>
                                 </v-simple-table>
                             </v-col>
                         </v-row>
                     </v-container>

                </v-card-text>
                <v-card-actions>
                    <v-btn
                          color="blue-grey darken-4"
                          @click="factory_dialog = false"
                        >
                          Close
                        </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>

</template>

<script>
    import ProductUpload from '@/components/cpanel/ProductUpload'

    export default {
        name: "Products",
        data: () => ({
            title: "Products",
            products: [],
            page:1,
            offset:0,
            limit:50,
            max_count:0,
            filter:[],
            search_url:'',
            loading:true,
            headers: [
                {
                    text: 'Album',
                    value: 'album',
                    align: 'center',
                    custom: 'image'
                },
                {
                    text: 'Item Num',
                    value: 'item_number',
                    align: 'center'
                },
                {
                    text: 'Name',
                    value: 'name',
                    align: 'center'
                },
                {
                    text: 'FOB',
                    value: 'fob_price',
                    align: 'center'
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    custom: 'actions',
                    align: 'center'
                }
            ],
            factory_dialog: false,
            factory: {
                factory:{}
            }
        }),
        created () {
            this.backend_url = this.$store.state.backend_url;
            this.load_products();
        },
        watch: {
            page(val) {
                this.load_products();
            },
        },
        methods: {
            load_factory_details(p)
            {
              this.factory = p;
              this.factory_dialog = true;
            },
            edit_product(id)
            {
                window.open('/c-panel/product/'+id, '_blank');

                // this.$router
                //     .push({path: '/c-panel/product/'+id});
            },
            load_products() {
                let offset = (this.page - 1) * this.limit;
                console.log('params');
                if(this.$route.query.organisation)
                {
                  this.search_url += "&factory="+this.$route.query.organisation;
                }
                this.$http.get('/admins/products/?limit=50&offset='+offset+this.search_url)
                    .then((response)=>{
                        console.log(response.data);
                        this.max_count = parseInt(((response.data.count + 50) / this.limit));
                        this.products = response.data.results;
                        this.loading = false;
                    })
                    .catch((error)=>{
                        this.loading = false;
                        console.log(error);
                    });
            },
            showUploadModal() {
                this.$modal.show(
                    ProductUpload,
                    {
                        text: 'This text is passed as a property'
                    },
                    {
                        adaptive: true,
                        scrollable: true,
                        reset:true,
                        height: 'auto',
                        width: '80%',
                        clickToClose: false
                    },
                    )
            },
            seekValue(item, value) {
                if (value.includes('.')) {
                    let map = value.split('.');
                    let res = item;
                    for (let pos of map)
                        res = res[pos] ? res[pos] : res;
                    return res;
                } else return item[value];
            },
            filter_results(filter)
            {
                let search_url = '';
                for (let prop in filter) {
                    // skip loop if the property is from prototype
                    if(!filter.hasOwnProperty(prop)) continue;
                    search_url += '&'+prop+'='+filter[prop];
                }
                this.search_url = search_url;
                this.page = 1;
                this.load_products();
            }
        }


    }
</script>

<style scoped>

</style>
